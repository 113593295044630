import { hasPermission } from 'Components/Authorize';
import { getCurrentUrl } from 'preact-router';
import { Permission } from 'zl-shared';

import { HabitatListItem } from '@/queries/habitats/types';
import { UserState } from '@/redux/slices/user/types';

import { loadPage, MULTI_FREE_HABITAT_COUNT } from '../../helpers';
import store from '../../redux/store';
import { goToHabitatPage } from '../habitat/helpers';
import { goToSignup } from '../home/helpers';

export const getFreeHabitatIds = ({
  habitats,
  subscription,
  loggedIn,
  isMultiFreeHabitat = false,
}: {
  habitats: HabitatListItem[];
  subscription: Pick<UserState['subscription'], 'active' | 'freeHabitats'>;
  loggedIn: boolean;
  isMultiFreeHabitat?: boolean;
}) => {
  if (!loggedIn) {
    const freeHabitatCount = isMultiFreeHabitat ? MULTI_FREE_HABITAT_COUNT : 1;

    return habitats
      .filter(({ isFreemium }) => isFreemium)
      .sort((a, b) => Number(b.online) - Number(a.online) || a.freemiumPriority - b.freemiumPriority)
      .slice(0, freeHabitatCount)
      .map((habitat) => habitat._id);
  }
  if (!subscription.active) {
    return subscription.freeHabitats;
  }
  return [];
};

export const getHabitatDescription = ({
  habitat,
  loggedIn,
  freeHabitatIds,
}: {
  habitat: HabitatListItem;
  loggedIn: boolean;
  freeHabitatIds: Set<string> | undefined;
}) => {
  if (loggedIn) {
    return habitat.description;
  }
  if (freeHabitatIds?.has(habitat._id)) {
    return 'zoolife features live animal streams from the worlds’ top zoos, hosted by experts. Sign up for unlimited access to this free habitat.';
  }
  return 'zoolife features live animal streams from the worlds’ top zoos, hosted by experts. Purchase a pass to explore all habitats on zoolife.';
};

export const isHabitatLocked = ({ habitatId }: { habitatId: string }) => {
  const {
    user: { logged, subscription },
  } = store.getState();

  if (logged) {
    if (subscription.active || hasPermission(Permission.Map.AllHabitats)) {
      return false;
    }

    return !subscription.freeHabitats?.includes(habitatId);
  }
  return false;
};

export const getPrimaryButtonTitle = ({
  habitat,
  loggedIn,
  freeHabitatIds,
}: {
  habitat: HabitatListItem;
  loggedIn: boolean;
  freeHabitatIds: Set<string> | undefined;
}) => {
  if (loggedIn) {
    // eslint-disable-next-line object-curly-newline
    if (
      isHabitatLocked({
        habitatId: habitat._id,
      })
    ) {
      return 'Unlock';
    }
    return 'Visit';
  }
  if (freeHabitatIds?.has(habitat._id)) {
    return 'Sign Up';
  }
  return 'Buy a Pass';
};

export const onClickCardHandler = ({
  habitat,
  loggedIn,
  termsAccepted,
  openTermsModalAction,
  freeHabitatIds,
}: {
  habitat: HabitatListItem;
  loggedIn: boolean;
  termsAccepted: boolean;
  openTermsModalAction: () => void;
  freeHabitatIds: Set<string> | undefined;
}) => {
  if (loggedIn) {
    if (!termsAccepted) {
      openTermsModalAction();
    } else if (
      isHabitatLocked({
        habitatId: habitat._id,
      })
    ) {
      loadPage('/plans');
    } else {
      goToHabitatPage(habitat.zoo?.slug, habitat.slug);
    }
  } else if (freeHabitatIds?.has(habitat._id)) {
    if (getCurrentUrl().includes('/album/')) {
      window.parent.location = `${window.document.location.origin}/signup?utm_source=zoolife&utm_medium=public&utm_campaign=albumpage`;
    } else {
      goToSignup();
    }
  } else {
    loadPage('/prices');
  }
};
