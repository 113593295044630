import { useState } from 'preact/hooks';

import ControlledAccordion, { AccordionProps as ControlledAccordionProps } from './controlled';

interface AccordionProps extends Omit<ControlledAccordionProps, 'onPress' | 'expanded'> {
  expanded?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}

const Accordion = ({ expanded, children, onExpand, onCollapse, ...rest }: AccordionProps) => {
  const [expand, setExpand] = useState(expanded ?? false);

  const onPress = () => {
    if (!expand) {
      onExpand?.();
    } else {
      onCollapse?.();
    }
    setExpand(!expand);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ControlledAccordion expanded={expand} onPress={onPress} {...rest}>
      {children}
    </ControlledAccordion>
  );
};

export default Accordion;
