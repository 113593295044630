import { useEffect } from 'preact/hooks';

const useOnMount = (fn: () => void) => {
  useEffect(() => {
    fn();
    // only run once on component mounted, ignore the later call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnMount;
