import { createSelector } from '@reduxjs/toolkit';
import { isFreeUser, Role } from 'zl-shared/dist/auth';

import { AppState } from '../helper';

export const selectIsFreeUser = createSelector(
  (state: AppState) => state.user.role,
  (state: AppState) => state.user.subscription.active ?? false,
  (role, active) => isFreeUser(role as Role, { active }),
);

export const selectFreeHabitatsIndexMap = createSelector(
  (state: AppState) => state.user.subscription.freeHabitats,
  (freeHabitats: string[] | undefined) =>
    freeHabitats?.reduce((acc, id, index) => {
      acc.set(id, index);
      return acc;
    }, new Map<string, number>()),
);

export const selectShouldShowAppOnboardingModal = createSelector(
  (state: AppState) => state.user.logged,
  (state: AppState) => state.user.isAppOnboarded,
  (state: AppState) => state.modals.appOnboardingModal,
  selectIsFreeUser,
  (logged, isAppOnboarded, state, isFreeUser) =>
    logged &&
    !isAppOnboarded &&
    !Object.entries(state)
      // check `habitatsSelector` only if user is a free user
      .map(([k, v]) => (k === 'habitatsSelector' && !v ? !isFreeUser : v))
      .every(Boolean),
);
