import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutlineButton } from 'Components/Buttons';
import { FunctionalComponent } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import { trackMP } from 'Shared/mp';
import useFetch from 'use-http';

import { useSelector } from '@/redux/helper';
import { toggleFavoriteHabitat } from '@/redux/slices/user/actions';

import style from './style.scss';

interface FollowButtonProps {
  habitatId: string;
  title: string;
}

const FollowButton: FunctionalComponent<FollowButtonProps> = ({ habitatId, title }) => {
  const dispatch = useDispatch();
  const isLiked = useSelector((state) => state.user?.favoriteHabitats?.includes(habitatId));
  const [error, setError] = useState<boolean>(false);
  const { post, del, response, loading } = useFetch(buildURL('/habitats/favorite'), {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  const likeIcon = useMemo(() => {
    if (loading) {
      return faSpinner;
    }
    if (error) {
      return faTimes;
    }
    if (isLiked) {
      return faHeartSolid;
    }
    return faHeart;
  }, [isLiked, loading, error]);

  const onFollow = async (evt: any) => {
    evt.stopPropagation();
    const trackingData = {
      habitatName: title,
      habitatId,
    };

    if (isLiked) {
      await del({ habitatId });
      trackMP('habitat-unfavourited', trackingData);
    } else {
      await post({ habitatId });
      trackMP('habitat-favourited', trackingData);
    }

    if (response.ok) {
      dispatch(toggleFavoriteHabitat(habitatId));
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  return (
    <OutlineButton
      size="small"
      className={`${style.trailerButton} !mr-2 hover:!text-primary-green active:!text-primary-green ${
        isLiked ? '!bg-primary-green' : '!bg-white'
      }`}
      onClick={onFollow}
      disabled={loading}
      label={
        <div className="flex items-center">
          <FontAwesomeIcon
            className="mr-2"
            icon={likeIcon}
            color={isLiked && !loading ? '#D3180C' : 'primary-green'}
            spin={likeIcon === faSpinner}
          />
          <span className={`${isLiked ? '!text-white' : '!text-primary-green'} ml-auto mr-auto !font-medium`}>
            {isLiked ? 'Following' : 'Follow'}
          </span>
        </div>
      }
    />
  );
};

export default FollowButton;
