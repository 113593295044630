import { faFilm } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { OutlineButton } from 'Components/Buttons';
import FollowButton from 'Components/Map/HabitatMobileCard/FollowButton';
import { Text } from 'grommet';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { logLandingButton } from 'Shared/ga';
import { trackMP } from 'Shared/mp';

import { useSelector } from '@/redux/helper';
import Accordion from '@/routes/account/Accordion';

import style from './style.scss';

const HabitatMobileCard = ({
  habitat,
  isOffline,
  isLiveTalk,
  onShowTrailer,
  showFreemiumTag,
  description,
  isLocked,
  primaryButtonTitle,
  online,
  onClickPrimaryButton,
}) => {
  const subscription = useSelector((state) => state.user.subscription);
  const isLogged = useSelector((state) => state.user.logged);
  const [isOpen, setIsOpen] = useState(false);

  const habitatRef = useRef(habitat);
  useEffect(() => {
    habitatRef.current = habitat;
  }, [habitat]);
  useEffect(() => {
    if (isOpen && habitatRef.current) {
      trackMP('mapAccordionExpanded', {
        habitatName: habitatRef.current.title,
        habitatId: habitatRef.current._id,
      });
    }
  }, [isOpen]);

  if (!habitat && !subscription) {
    return null;
  }

  const onHabitatClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    onClickPrimaryButton();

    if (!isLogged) {
      logLandingButton(`Habitat-${primaryButtonTitle.replaceAll(' ', '-')}`);
    }
  };

  const onPressTrailer = () => {
    onShowTrailer(habitat.trailer);
    if (!isLogged) {
      logLandingButton(`Habitat-Trailer`);
    }
  };

  return (
    <Accordion
      noAbsoluteIcon
      className={clsx('py-4', style.mapAccordion, style.leftAlignedIcon)}
      headerWrapperClassName="!min-h-fit !flex !items-center"
      header={
        <div className="flex min-w-0 grow items-center" onClick={() => setIsOpen(!isOpen)}>
          <div
            className={clsx(
              style.habitatImage,
              showFreemiumTag && style.freemium,
              habitat.disabled && style.disabled,
              isOffline && style.offline,
              online && style.online,
            )}
            data-group="Habitat List Item"
            data-title={habitat.title}
            data-livetalk={isLiveTalk}
            data-onlinehabitat={online && !isLiveTalk}
            data-offlinehabitat={isOffline && !isLiveTalk}
          >
            <img src={habitat.profileImage} alt="profile" />
            <div className={style.notificationDot} />
            {isLiveTalk && <div className={style.liveTalkBadge}>Live Event</div>}
          </div>
          <div className="ml-4 flex min-w-0 grow flex-col">
            <div>
              <a
                data-native
                className="preflight preflight-a"
                onClick={onHabitatClick}
                href={`/h/${habitat.zoo.slug}/${habitat.slug}`}
              >
                <h3 className="preflight preflight-h3 inline-block text-sm font-medium">{habitat.title}</h3>
              </a>
            </div>
            <p className="preflight preflight-p line-clamp-1 min-w-0 grow text-ellipsis text-xs/5 font-medium">
              {habitat.zoo.name}
            </p>
          </div>
          <a
            data-native
            className={style.viewButton}
            onClick={onHabitatClick}
            href={`/h/${habitat.zoo.slug}/${habitat.slug}`}
          >
            {isLocked ? 'Upgrade' : 'Visit'}
          </a>
        </div>
      }
    >
      <div className="pt-2">
        <div className={style.descriptionContainer}>
          <Text size="large">{description}</Text>
        </div>
        <div className="items-left mb-1 ml-7 flex">
          <FollowButton habitatId={habitat._id} title={habitat.title} />
          {habitat.trailer && (
            <OutlineButton
              size="small"
              className={style.trailerButton}
              onClick={onPressTrailer}
              label={
                <div>
                  <FontAwesomeIcon icon={faFilm} />
                  <span className="ml-2 !font-medium">View Trailer</span>
                </div>
              }
            />
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default memo(HabitatMobileCard);
