import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ComponentChild } from 'preact';
import { PropsWithChildren } from 'preact/compat';

import style from './style.scss';

export type AccordionProps = PropsWithChildren<{
  expanded: boolean;
  header: ComponentChild;
  noAbsoluteIcon?: boolean;
  headerWrapperClassName?: string;
  iconClassName?: string;
  className?: string;
  onPress?: () => void;
}>;

const Accordion = ({
  expanded,
  children,
  header,
  noAbsoluteIcon,
  headerWrapperClassName,
  iconClassName,
  className,
  onPress,
}: AccordionProps) => {
  return (
    <div className={clsx(style.accordionContainer, className, { [style.expand]: expanded })}>
      <button type="button" className={clsx(style.header, headerWrapperClassName)} onClick={onPress}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(noAbsoluteIcon ? style.noAbsoluteIcon : style.icon, iconClassName)}
        />
        {header}
      </button>
      <div className={style.body}>{children}</div>
    </div>
  );
};

export default Accordion;
